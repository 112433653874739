<template>
  <form action>
    <label for>
      <div class="form-item">
        <div class="label require">组分</div>
        <input
          placeholder="请输入产品组分"
          id="DPzufen"
          v-model="DPzufen"
          type="text"
        />
      </div>
    </label>
    <label for>
      <div class="form-item">
        <div class="label require">标准值</div>
        <input
          placeholder="请输入产品标准值"
          id="DPnondu"
          v-model="DPnondu"
          type="text"
        />
      </div>
    </label>
    <label for>
      <div class="form-item">
        <div class="label require">规格</div>
        <input
          placeholder="请输入产品规格"
          id="DPguige"
          v-model="DPguige"
          type="text"
        />
      </div>
    </label>
    <label for>
      <div class="form-item">
        <div class="label require">数量</div>
        <input
          placeholder="请输入定制数量"
          id="DPnumber"
          v-model="DPnumber"
          type="number"
        />
      </div>
    </label>
    <div class="form-item" v-if="!userInfo.id">
      <div class="label require">姓名</div>
      <input
        type="text"
        class="made-input"
        placeholder="您的姓名"
        v-model="DUname"
        name="DUname"
        id="DUname"
        required
      />
    </div>
    <div class="form-item" v-if="!userInfo.id">
      <div class="label require">联系方式</div>
      <input
        type="text"
        class="made-input"
        placeholder="您的联系方式"
        v-model="DUcontact"
        name="DUcontact"
        id="DUcontact"
        required
      />
    </div>
    <label for>
      <div class="form-item code">
        <div class="label">
          <img id="getCode" :src="codeImg" @click="getCodeImg" alt />
        </div>
        <input
          placeholder="请输入验证码"
          id="code"
          v-model="code"
          type="text"
        />
      </div>
    </label>
    <!-- <label for>
      <div class="form-item textarea-form-item">
        <textarea
          placeholder="如有特殊要求或者参考产品可在备注中说明"
          v-model="DPbeizhu"
          id="DPbeizhu"
        ></textarea>
      </div>
    </label> -->
  </form>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/36b28c56331fefd0e0541943db1facc92/1"
        >
          <img src="@/assets/images/icon5-1.png" alt />
          客服
        </a>
      </li>
      <li class="footer-li">
        <a href="javascript:;" id="collect"></a>
      </li>
      <li
        id="add"
        @click="handleBack"
        class="footer-li button"
        style="margin-left: 1.6rem"
      >
        <a href="javascript:;">返回</a>
      </li>
      <li
        class="footer-li button"
        style="background-color: #b9135a; margin-right: 0"
        @click="handlePostAdd"
      >
        <a href="javascript:void(0);">定制</a>
      </li>
    </ul>
<!--
    <red-package
       v-if="showRedPackage"
      @close="onOpenPackage"
      :src="require('../assets/img/hongbao.png')"
      target="NewsDetail"
      :params="{ newsid: 15835 }"
    ></red-package>-->
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, onMounted, inject } from "vue";
import { useRouter } from "vue-router";

//  utils
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import { nextTick } from "q";
import $ from "jquery";
// import RedPackage from "@/components/common/RedPackage.vue";

export default defineComponent({
  name: "product",
  // components: { RedPackage },
  setup() {
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    const router = useRouter();
    const DPnumber = ref("");
    const DPzufen = ref("");
    const DPnondu = ref("");
    const DUname = ref<string>(userInfo.realName);
    const DPguige = ref("");
    const DUcontact = ref<string>(userInfo.mobile);
    const code = ref("");

    // const showRedPackage = ref(false);
    function handlePostAdd() {
      nextTick(() => {
        if (DPzufen.value.length < 1) {
          Toast({
            title: "请输入待定制产品的组分",
            type: "warning",
          });
          $("#DPzufen").focus();
          return false;
        }
        if (DPzufen.value.length > 100) {
          Toast({
            title: "产品的组分不能超过100个字符",
            type: "warning",
          });
          $("#DPzufen").focus();
          return false;
        }
        if (DPnondu.value.length < 1) {
          Toast({
            title: "请输入待定制产品的标准值",
            type: "warning",
          });
          $("#DPnondu").focus();
          return false;
        }
        if (DPnondu.value.length > 100) {
          Toast({
            title: "产品的标准值不能超过100个字符",
            type: "warning",
          });
          $("#DPnondu").focus();
          return false;
        }
        if (DPguige.value.length < 1) {
          Toast({
            title: "请输入待定制产品的规格",
            type: "warning",
          });
          $("#DPguige").focus();
          return false;
        }
        if (DPguige.value.length > 100) {
          Toast({
            title: "产品的规格不能超过100个字符",
            type: "warning",
          });
          $("#DPguige").focus();
          return false;
        }
        if (DPnumber.value.length < 1) {
          Toast({
            title: "请输入待定制产品的数量",
            type: "warning",
          });
          $("#DPnumber").focus();
          return false;
        }
        if (Number(DPnumber.value) < 1) {
          Toast({
            title: "数量需大于0",
            type: "warning",
          });
          $("#DPnumber").val("").focus();
          return false;
        }
        if (DPnumber.value.length > 10) {
          Toast({
            title: "产品的数量不能超过10个字符",
            type: "warning",
          });
          $("#DPnumber").focus();
          return false;
        }

        if (userInfo.mobile) {
          if (!userInfo.realName) {
            DUname.value = userInfo.nickName
          }
        } else {
          if (
            (DUname.value && DUname.value.length < 2 && DUname.value.length > 15) ||
            !DUname.value
          ) {
            Toast({
              title: "请输入合法姓名",
              type: "warning",
            });
            $("#DUname").focus();
            return false;
          }
        }

        if (userInfo.mobile) {
          DUcontact.value = userInfo.mobile
        } else {
          if ((DUcontact.value && DUcontact.value.length > 20) || (DUcontact.value && DUcontact.value.length < 1) || !DUcontact.value) {
            Toast({
              title: "请输入合法联系方式",
              type: "warning",
            });
            $("#DUcontact").focus();
            return false;
          }
        }

        if (!code.value && code.value.length < 1) {
          Toast({
            title: "请输入验证码",
            type: "warning",
          });
          $("#code").focus();
          return false;
        }

        // if (DPbeizhu.value.length > 255) {
        //   Toast({
        //     title: "备注不能超过255个字符",
        //     type: "warning",
        //   });
        //   $("#DPbeizhu").focus();
        //   return false;
        // }
        const formData = new FormData();
        formData.append("DPnumber", DPnumber.value);
        formData.append("DPnondu", DPnondu.value);
        formData.append("DUname", DUname.value);
        formData.append("DPzufen", DPzufen.value);
        formData.append("DPguige", DPguige.value);
        formData.append("DUcontact", DUcontact.value);
        formData.append("Code", code.value);
        api
          .post("/M/Server/DoSubmitDingzhi", formData)
          .then((res) => {
            if (res.data.success) {
              Toast({
                title: res.data.msg,
                type: "success",
                onClose: () => {
                  // router.push("/product");
                },
              });
              // showRedPackage.value = true;
              DPnumber.value = "";
              DPzufen.value = "";
              DPnondu.value = "";
              DUname.value = "";
              DPguige.value = "";
              DUcontact.value = "";
              code.value = "";
            } else {
              Toast({
                title: res.data.msg,
                type: "warning",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }

    const codeImg = ref("/M/Server/GetVerifyCodeImg");

    function getCodeImg() {
      codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
    }

    function handleBack() {
      if (window.history.length <= 1) {
        router.push({ path: "/" });
      } else {
        router.back();
      }
    }

    // function onOpenPackage() {
    //   showRedPackage.value = false;
    // }
    return {
      DPnumber,
      DPnondu,
      DUname,
      DPzufen,
      DPguige,
      DUcontact,
      // DPbeizhu,
      code,
      handleBack,
      handlePostAdd,
      codeImg,
      getCodeImg,
      // onOpenPackage,
      // showRedPackage,
      userInfo
    };
  },
});
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1.012px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}

footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-nav {
  position: relative;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}

.footer-li img {
  display: block;
  width: 20;
  height: 20px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -10px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #b9135a;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 11px;
}

footer .button a {
  color: #fff;
}

form {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 10px 50px;
  box-sizing: border-box;
}

.form-item {
  width: 100%;
  height: 45px;
  padding-left: 75px;
  box-sizing: border-box;
  position: relative;
  background-color: #f4f4f4;
  margin-bottom: 15px;
}

.label {
  width: 78px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 10px;
  line-height: 45px;
  color: #444444;
  font-size: 15px;
}
.require {
  padding-left: 10px;
}

.require::after {
  content: "*";
  position: absolute;
  left: 0;
  top: 0;
  color: #b9135a;
}

.form-item input {
  width: 100%;
  line-height: 45px;
  height: 45px;
  background-color: transparent;
  outline: none;
  border: none;
  vertical-align: middle;
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
}

.code img {
  width: 92px;
  height: 30px;
  margin-top: 7.5px;
}

.textarea-form-item {
  padding: 10px;
  height: 100.012px;
}

textarea {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 15px;
  color: #444444;
  font-family: 微软雅黑;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #666 !important;
  font-size: 14px !important;
  font-family: 微软雅黑;
}
</style>
